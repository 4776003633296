import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Dimmer } from 'semantic-ui-react';
import classNames from 'classnames';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import { Location } from 'history';
import QueryString from 'qs';
import pick from 'lodash.pick';
import { TableAction } from '../../../constants/table.constant';
import { INavigationLocation } from '../../molecules/MOLNavigation/MOLNavigation.component';
import { secondLargestValue } from '../../../utils/common';
import styles from './ORGDataTable.module.scss';
import {
  MOLTableToolBar,
  IMOLTableToolbar,
  IMOLTableToolbarFilteredBy,
} from './MOLTableToolBar/MOLTableToolBar.component';
import {
  ITableFilter,
  IMOLTableFilter,
} from './MOLTable-filter/MOLTable-filter.component';
import { ATMLoader } from '../../atoms/ATMLoader/ATMLoader.component';
import { ATMPagination } from '../../atoms/ATMPagination/ATMPagination.component';
import {
  ATMTable,
  IATMTableCellProps,
  IATMTableHeaderCellProps,
  IATMTableProps,
} from '../../atoms/ATMTable/ATMTable.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMDropdown } from '../../atoms/ATMDropdown/ATMDropdown.component';
import { ATMGrid } from '../../atoms/ATMGrid/ATMGrid.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

type IORGDataTable = Record<string, any>;

type IORGDataTableRowProps = {
  expandDisabled: boolean;
  isExpand: boolean;
  setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

export type IORGDataTableColumn<T extends IORGDataTable> = {
  index: string;
  title: React.ReactNode;
  width?: string;
  sortable?: boolean;
  render?: (
    value: any,
    record: T,
    index: number,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  renderAction?: (
    value: any,
    record: T,
    index: number,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  actionFlag?: boolean;
  iconName?: string;
  cellProps?: IATMTableCellProps;
  headerProps?: IATMTableCellProps;
  childColumns?: Omit<IORGDataTableColumn<T>, 'childColumns'>[];
  isVisible?: boolean;
  visibilityToggle?: boolean;
  sortingOrder?: number;
  cellClass?:
    | string
    | ((value: any, record: T, index: number) => string | undefined);
  rowSpan?: any;
  settingColumn?: string;
};

export type IORGDataTableQueryState<
  T extends string = any,
  U = { [K in T]: any }
> = {
  page: number;
  limit: number;
  sort?: string;
  order?: IATMTableHeaderCellProps['sorted'];
  filters?: ITableFilter[];
} & Partial<U>;

export type IORGDataTableUpdateOptions = {
  action?: TableAction;
};

export type IORGDataTableOnUpdate = (
  data: Partial<IORGDataTableQueryState>,
  options: IORGDataTableUpdateOptions
) => void;

// Allowed state to be used
const tableState: Array<keyof IORGDataTableQueryState> = [
  'page',
  'limit',
  'order',
  'sort',
  'filters',
];

const createURLString = (
  location: Location,
  data: Partial<IORGDataTableQueryState>,
  addParams?: string[]
) => {
  const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  let path = location.pathname;

  const newQuery: Record<string, any> = {
    ...data,
  };

  const state = tableState.concat(addParams || []);

  // We will include the other url parameters when generating new url
  Object.keys(query).forEach((key) => {
    if (state.indexOf(key as keyof IORGDataTableQueryState) < 0) {
      newQuery[String(key)] = query[String(key)];
    }
  });

  if (newQuery && Object.keys(newQuery).length >= 1) {
    const urlReadyQuery = QueryString.stringify(newQuery);
    path += `?${urlReadyQuery}`;
  }

  return path;
};

type IRowProps<T extends IORGDataTable> = {
  rowKey: number;
  data: T;
  columnsData: IORGDataTableColumn<T>[];
  placeholder?: string | number;
  queryState: IORGDataTableQueryState;
  expandableRowDisabled?: (data: T, index: number) => boolean;
  expandableRowsComponent?: (
    data: T,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  customRowComponent?: (data: T, key: number) => React.ReactNode;
};

const Row = <T extends IORGDataTable>({
  data,
  rowKey,
  queryState,
  columnsData,
  placeholder,
  expandableRowDisabled,
  expandableRowsComponent,
}: React.PropsWithChildren<IRowProps<T>>) => {
  const [isExpand, setIsExpand] = useState(false);
  const props = {
    expandDisabled: expandableRowDisabled
      ? expandableRowDisabled(data, rowKey)
      : false,
    isExpand,
    setIsExpand,
  };

  const displayValue = (
    value,
    index,
    key,
    render: IORGDataTableColumn<T>['render']
  ) => {
    const display = render
      ? render(get(value, index), value, key, props)
      : get(value, index);

    if (value.emptyRow === 'emptyRow') {
      return '';
    }

    if (
      placeholder &&
      ((typeof display === 'string' && display.trim() === '') ||
        display === null ||
        display === undefined)
    ) {
      return placeholder;
    }

    return display;
  };

  const getClassName = useCallback(
    (cellClass, index, key) => {
      const value = get(data, index);

      if (typeof cellClass === 'function') {
        return cellClass(value, data, key);
      }

      return cellClass &&
        Object.keys(cellClass).includes(
          typeof value === 'string' ? value.toLowerCase() : value
        )
        ? cellClass[typeof value === 'string' ? value.toLowerCase() : value]
        : '';
    },
    [data]
  );

  return (
    <>
      <ATMTable.Row
        className={classNames(
          data.state,
          data.rowColor,
          data.emptyRow,
          isExpand ? styles.rowClass : ''
        )}
      >
        {columnsData.map(
          (
            {
              index,
              render,
              cellProps,
              childColumns,
              isVisible,
              cellClass,
              rowSpan,
            },
            key
          ) => {
            if (isVisible === undefined ? true : isVisible) {
              if (childColumns && childColumns.length) {
                return childColumns.map(({ ...child }, childKey) => (
                  <ATMTable.Cell
                    {...child.cellProps}
                    key={`cell_${queryState.page}_${rowKey}_${key}_${childKey}`}
                  >
                    {displayValue(data, child.index, rowKey, child.render)}
                  </ATMTable.Cell>
                ));
              }
              return rowSpan ? (
                Object.keys(data).includes(index) && (
                  <ATMTable.Cell
                    {...cellProps}
                    key={`cell_${queryState.page}_${rowKey}_${key}`}
                    className={getClassName(cellClass, index, rowKey)}
                    rowSpan={getClassName(rowSpan, index, rowKey)}
                  >
                    {displayValue(data, index, rowKey, render)}
                  </ATMTable.Cell>
                )
              ) : (
                <ATMTable.Cell
                  {...cellProps}
                  key={`cell_${queryState.page}_${rowKey}_${key}`}
                  className={getClassName(cellClass, index, rowKey)}
                  rowSpan={getClassName(rowSpan, index, rowKey)}
                >
                  {displayValue(data, index, rowKey, render)}
                </ATMTable.Cell>
              );
            }
            return null;
          }
        )}
      </ATMTable.Row>

      {isExpand && expandableRowsComponent && (
        <ATMTable.Row>
          <ATMTable.Cell
            colSpan={columnsData.reduce((acc, cur) => {
              const columns = cur?.childColumns ? cur.childColumns.length : 1;
              return acc + columns;
            }, 0)}
            className={styles.noPadding}
          >
            {expandableRowsComponent(data, props)}
          </ATMTable.Cell>
        </ATMTable.Row>
      )}
    </>
  );
};

export type IORGDataTableProps<T extends IORGDataTable> = Omit<
  IATMTableProps,
  'columns'
> & {
  location?: INavigationLocation; // This will attach table's state in the URL
  handleLocation?: (url: string) => void;
  columns: IORGDataTableColumn<T>[];
  defaultState?: Partial<IORGDataTableQueryState>; // Note: If location is enabled, defaultState will not be used when there are table state values in the URL params.
  data: T[];
  total?: number; // Only add total if pagination is managed in the server
  rowsPerPageOptions?: number[]; // List of options for rows per page
  loading?: boolean;
  noLoadingText?: boolean;
  loaderSize?: string;
  toggleSize?: string;
  sortable?: boolean;
  showPagination?: boolean;
  addParams?: string[]; // Ability to add additional state in the table
  counter?: boolean; // This will show or hide `Showing 1 of 10` info text
  filteredBy?: IMOLTableToolbarFilteredBy; // This will allow update of text in filter. If false, it will removed the Filtered By info text
  onChange?: (
    state: IORGDataTableQueryState,
    options: IORGDataTableUpdateOptions
  ) => void;
  onChangeData?: (updatedData) => any;
  children?: (data: {
    state: IORGDataTableQueryState;
    setState: (data: Partial<IORGDataTableQueryState>) => void;
  }) => {
    toolbars?: IMOLTableToolbar[] | IMOLTableFilter;
    filters?: IMOLTableFilter;
  };
  noDataText?: string | React.ReactNode;
  tableLabel?: any;
  noMarginTop?: boolean;
  customFilter?: boolean;
  customFilterContent?: React.ReactNode;
  customFilterBtn?: React.ReactNode;
  removeCancelApplyinfilters?: boolean;
  compact?: boolean;
  columnFilter?: boolean;
  handleUpdatedColumnData?: (columns: IORGDataTableColumn<T>[]) => void;
  dragDropIcon?: React.ReactNode;
  tableHeight?: string;
  filterCollapsed?: boolean;
  placeholder?: string | number;
  defaultRowsPerPage?: number;
  expandableRowDisabled?: (data: T, index: number) => boolean;
  expandableRowsComponent?: (
    data: T,
    props: IORGDataTableRowProps
  ) => React.ReactNode;
  columnSettingsHeader?: string;
  expandIconStart?: boolean;
  allButtonSize?: string;
  hideThBorder?: boolean;
  getFromToData?: (any) => void;
  filters?: IMOLTableFilter;
  toolbars?: IMOLTableToolbar[] | IMOLTableFilter;
};

const totalRowsOptions = [10];

const defaultQueryState: IORGDataTableQueryState = {
  page: 1,
  limit: 10,
};

const ORGDataTable = <T extends IORGDataTable>({
  data,
  columns,
  loading = false,
  noLoadingText = false,
  loaderSize = 'large',
  location,
  toggleSize,
  rowsPerPageOptions = totalRowsOptions,
  addParams,
  counter = false,
  celled = true,
  children,
  onChange,
  onChangeData,
  filteredBy,
  showPagination = true,
  noDataText,
  tableLabel,
  total: totalProp,
  noMarginTop = false,
  customFilter = false,
  customFilterContent,
  customFilterBtn,
  removeCancelApplyinfilters = false,
  compact,
  columnFilter = false,
  dragDropIcon,
  handleUpdatedColumnData,
  tableHeight = '',
  filterCollapsed = false,
  placeholder = String.fromCharCode(8211),
  defaultRowsPerPage,
  expandableRowDisabled,
  expandableRowsComponent,
  handleLocation,
  columnSettingsHeader,
  expandIconStart = false,
  tableRowDisplay,
  customRowComponent,
  allButtonSize = 'tiny',
  hideThBorder = false,
  getFromToData,
  defaultState,
  filters: propFilters,
  toolbars: propToolbars,
  ...props
}: React.PropsWithChildren<IORGDataTableProps<T>>) => {
  const total = totalProp || data.length;
  const [columnsData, setColumnsData] = useState(columns);
  const actionRowElement: any = columns.find(
    (value) => value.index === 'expand'
  );
  const foundIdx = columnsData.findIndex((value) => value.index === 'expand');
  if (expandIconStart) {
    columnsData.splice(foundIdx, 1);
    columnsData.unshift(actionRowElement);
  }

  useEffect(() => {
    setColumnsData((values) => {
      return values.map((column) => {
        const result = columns.find((value) => value.index === column.index);

        return {
          ...(result || column),
          isVisible: column.isVisible,
          visibilityToggle: column.visibilityToggle,
        };
      });
    });
  }, [columns, setColumnsData]);

  let count = data.length;

  const defaultLimit = useMemo(() => {
    if (showPagination) {
      if (defaultState?.limit) {
        return defaultState?.limit;
      }

      if (defaultRowsPerPage) {
        if (!rowsPerPageOptions.includes(defaultRowsPerPage)) {
          rowsPerPageOptions.push(defaultRowsPerPage);
        }

        return defaultRowsPerPage;
      }

      if (
        defaultState?.limit &&
        rowsPerPageOptions.includes(defaultState.limit)
      ) {
        return defaultState.limit;
      }

      if (rowsPerPageOptions.length > 2) {
        return secondLargestValue([...rowsPerPageOptions]);
      }

      return rowsPerPageOptions[0];
    }

    return data.length || 99999;
  }, [
    showPagination,
    defaultRowsPerPage,
    rowsPerPageOptions,
    defaultState,
    data,
  ]);

  const getParams = () => {
    let params: Record<string, any> = QueryString.parse(
      location?.search || '',
      {
        ignoreQueryPrefix: true,
      }
    );

    if (!params.page) {
      params = {
        ...params,
        ...defaultState,
      };
    }

    params.page = Number(params.page) || defaultQueryState.page;
    params.limit = Number(params.limit) || defaultLimit;
    // Only get the parameters needed by table state
    return pick(
      params,
      tableState.concat(addParams || [])
    ) as IORGDataTableQueryState;
  };

  const updateOptions = useRef<IORGDataTableUpdateOptions>();

  // If location is true, get the url parameters and set as state on initialized
  const [queryState, setQueryState] = useState<IORGDataTableQueryState>(
    location
      ? getParams()
      : {
          ...defaultQueryState,
          ...defaultState,
          limit: defaultLimit,
        }
  );

  // This will handle all the state changes for this component
  const handleUpdate: IORGDataTableOnUpdate = useCallback(
    (items, options) => {
      const state = {
        ...items,
      };

      // If empty state, we must always populate these params
      state.page = Number(state.page) || defaultQueryState.page;
      state.limit = Number(state.limit) || defaultLimit;
      if (location && handleLocation) {
        handleLocation(
          createURLString(
            location,
            {
              ...state,
            },
            addParams
          )
        );
      } else {
        let params = {
          ...state,
        };

        // Remove filters if empty
        if (params.filters && params.filters.length === 0) {
          const { filters, ...newParams } = params;

          params = newParams;
        }

        setQueryState(
          pick(
            params,
            tableState.concat(addParams || [])
          ) as IORGDataTableQueryState
        );
      }

      updateOptions.current = options;
    },
    [setQueryState, defaultLimit, handleLocation, location, addParams]
  );

  const handleSort = useCallback(
    (sortParam, orderParam) => {
      const order: IORGDataTableQueryState['order'] =
        orderParam === 'descending' ? 'ascending' : 'descending';
      const params = {
        sort: sortParam,
        order,
      };

      handleUpdate(
        {
          ...queryState,
          ...params,
        },
        {
          action: TableAction.SORT,
        }
      );
    },
    [handleUpdate, queryState]
  );

  // If location is true, the table will react on the uri changes
  // then call the onChange for the parent container
  useEffect(() => {
    if (location) {
      const params: IORGDataTableQueryState = getParams();
      setQueryState(params);

      if (onChange) {
        onChange(params, updateOptions?.current ?? {});
      }
    }
  }, [location, onChange]); // eslint-disable-line

  // If location is false, this will react to changes on state
  // and send it back to the parent container via onChange props
  useEffect(() => {
    if (onChange && !location) {
      onChange(queryState, updateOptions?.current ?? {});
    }
  }, [queryState, onChange, location]);

  const colGroup: React.ReactNode[] = [];

  const hasRowSpan = useMemo(
    () =>
      !columnsData.every(
        (column) => column.childColumns && column.childColumns.length
      ),
    [columnsData]
  );

  const getHeader = (
    {
      title,
      index,
      headerProps,
      sortable = true,
      childColumns = [],
    }: IORGDataTableColumn<T>,
    key: string,
    isChild = false
  ) => {
    const hProps: IATMTableHeaderCellProps = {
      ...headerProps,
      key: `header_${key}`,
    };

    if (props.sortable && sortable && !childColumns.length) {
      hProps.sorted = queryState.sort === index ? queryState.order : undefined;
      hProps.onClick = () => handleSort(index, queryState.order);
    }

    if (childColumns.length) {
      hProps.colSpan = childColumns.length;
    }

    if (!isChild && !childColumns.length && hasRowSpan) {
      hProps.rowSpan = 2;
    }

    return (
      <ATMTable.HeaderCell
        className={classNames({
          [styles.hideBorder]: hideThBorder,
        })}
        {...hProps}
      >
        {title}
      </ATMTable.HeaderCell>
    );
  };

  const headerList: React.ReactNode[][] = [];

  columnsData.forEach(({ ...value }, key) => {
    if (value.isVisible === undefined ? true : value.isVisible) {
      headerList[0] = [...(headerList[0] ?? []), getHeader(value, `${key}`)];

      if (value.childColumns && value.childColumns.length) {
        headerList[1] = [
          ...(headerList[1] ?? []),
          ...value.childColumns.map((v, k) => {
            colGroup.push(<col key={`col_${key}_${k}`} width={value.width} />);

            return getHeader(v, `${key}_${k}`, true);
          }),
        ];
      } else {
        colGroup.push(<col key={`col_${key}`} width={value.width} />);
      }
    }
  });

  let content = [
    <ATMTable.Row key="no-data" className={styles.noDataRow}>
      <ATMTable.Cell colSpan={colGroup.length} textAlign="center">
        <div className={styles.noDataText}>
          <span>
            {noDataText !== undefined ? (
              noDataText
            ) : (
              <>
                <ATMIcon name="info circle" />
                No records/data found for the selected criteria
              </>
            )}
          </span>
        </div>
      </ATMTable.Cell>
    </ATMTable.Row>,
  ];

  if (data.length) {
    let offset = 0;
    let list = [...data];

    // This will trigger the data table's built-in pagination and sorting.
    if (!totalProp) {
      offset = (queryState.page - 1) * queryState.limit;

      if (props.sortable && queryState.sort && queryState.order) {
        list = orderBy(
          list,
          [queryState.sort],
          [queryState.order === 'ascending' ? 'asc' : 'desc']
        );
      }
    }

    if (onChangeData) {
      onChangeData(list);
    }

    // Slice the data based on the limit set in the table
    list = list.slice(offset, offset + queryState.limit);

    count = list.length;
    content = list.map(({ ...value }, rowKey) => {
      const index = (queryState.page - 1) * queryState.limit + rowKey;

      return customRowComponent ? (
        customRowComponent(value, index)
      ) : (
        <Row
          key={`row_${total}_${index}`}
          data={value}
          rowKey={index}
          queryState={queryState}
          columnsData={columnsData}
          placeholder={placeholder}
          expandableRowDisabled={expandableRowDisabled}
          expandableRowsComponent={expandableRowsComponent}
        />
      );
    });
  }

  let optionLength = Math.ceil(Number(total) / Number(queryState.limit));

  // If the computed option length will equal to 0, we will set it to 1
  // so it will create at least 1 page
  if (optionLength === 0) {
    optionLength = 1;
  }

  const childrenProps = children
    ? children({
        state: queryState,
        setState: (state) =>
          handleUpdate(state, {
            action: TableAction.TOOLBAR,
          }),
      })
    : {
        filters: propFilters,
        toolbars: propToolbars,
      };

  const handleColumnFilterApply = useCallback(
    (filteredArray: IORGDataTableColumn<T>[]) => {
      setColumnsData(filteredArray);
      if (handleUpdatedColumnData) {
        handleUpdatedColumnData(filteredArray);
      }
    },
    [handleUpdatedColumnData, setColumnsData]
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.noMarginTop]: noMarginTop,
      })}
    >
      <Dimmer active={loading} inverted className={styles.dimmerHeight}>
        <ATMLoader size={loaderSize as any}>
          {noLoadingText ? '' : 'Loading'}
        </ATMLoader>
      </Dimmer>

      <MOLTableToolBar
        counter={counter}
        state={queryState}
        total={total}
        tableLabel={tableLabel}
        count={count}
        toolbars={childrenProps.toolbars ?? []}
        filters={childrenProps.filters}
        customFilter={customFilter}
        customFilterBtn={customFilterBtn}
        customFilterContent={customFilterContent}
        removeCancelApplyinfilters={removeCancelApplyinfilters}
        handleChange={handleUpdate}
        filteredBy={filteredBy}
        toggleSize={toggleSize}
        columnFilter={columnFilter}
        columns={columnsData}
        handleColumnFilterApply={handleColumnFilterApply}
        dragDropIcon={dragDropIcon}
        filterCollapsed={filterCollapsed}
        columnSettingsHeader={columnSettingsHeader}
        buttonSize={allButtonSize}
        getFromToData={getFromToData}
      />
      <div
        style={tableHeight ? { height: tableHeight, overflowY: 'auto' } : {}}
      >
        <ATMTable compress={compact} celled={celled} {...props}>
          <ATMResponsive greaterThan="mobile">
            <colgroup>{colGroup}</colgroup>
          </ATMResponsive>
          <ATMTable.Header>
            {headerList.map((headers, key) => (
              <ATMTable.Row key={key}>{headers}</ATMTable.Row>
            ))}
          </ATMTable.Header>
          <ATMTable.Body>{content}</ATMTable.Body>
        </ATMTable>
      </div>
      {showPagination && (
        <div className={styles.footer}>
          <ATMGrid columns={3} padded="vertically">
            <ATMGrid.Row>
              <ATMGrid.Column>
                {rowsPerPageOptions.length > 1 &&
                  total > rowsPerPageOptions[0] && (
                    <div
                      className={
                        compact ? styles.compactFontSize : styles.rowPerPage
                      }
                    >
                      <label>Rows per page : </label>
                      <ATMDropdown
                        size="mini"
                        value={queryState.limit}
                        onChange={(_, item) => {
                          handleUpdate(
                            {
                              ...queryState,
                              limit: Number(item.value),
                              page: 1,
                            },
                            {
                              action: TableAction.LIMIT,
                            }
                          );
                        }}
                        options={rowsPerPageOptions.map((num) => ({
                          key: num,
                          value: num,
                          text: num,
                        }))}
                      />
                    </div>
                  )}
              </ATMGrid.Column>
              <ATMGrid.Column textAlign="center">
                {total > rowsPerPageOptions[0] && (
                  <ATMPagination
                    size="mini"
                    className={compact ? styles.compactSize : ''}
                    activePage={queryState.page}
                    totalPages={optionLength}
                    onPageChange={(_, item) =>
                      handleUpdate(
                        {
                          ...queryState,
                          page: Number(item.activePage),
                        },
                        {
                          action: TableAction.PAGE,
                        }
                      )
                    }
                  />
                )}
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </div>
      )}
    </div>
  );
};

export { ORGDataTable };
