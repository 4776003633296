import React from 'react';
import {
  MOLNavigation,
  IMOLNavigationMenuProps,
  INavigationLocation,
} from '../../molecules/MOLNavigation/MOLNavigation.component';
import styles from './ORGExternalHeader.module.scss';
import { ATMBrandLogo } from '../../atoms/ATMBrandLogo/ATMBrandLogo.component';
import { IATMMenuItemProps } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

export type IORGExternalHeaderProps = {
  homeLink?: string;
  menus?: IMOLNavigationMenuProps[];
  location: INavigationLocation;
  handleLocation: (url: string) => void;
  onNavigationClick?: (data: IATMMenuItemProps) => void;
  logoName?: string;
  menuCount?: number;
};

const DesktopContainer: React.FC<IORGExternalHeaderProps> = ({
  homeLink = '/',
  menus,
  location,
  onNavigationClick,
  handleLocation,
  logoName = 'sempraExternal',
  menuCount,
}) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.topHeader}>
          <div>
            <ul className={styles.branding}>
              <li>
                <a
                  onClick={() => handleLocation(homeLink)}
                  role="button"
                  className={styles.logo}
                >
                  <ATMBrandLogo height={30} logoName={logoName} />
                </a>
              </li>
            </ul>
          </div>
          {menus && (
            <div id="navigation" className={styles.navigation}>
              <div>
                <div>
                  <MOLNavigation
                    location={location}
                    handleLocation={handleLocation}
                    menus={menus}
                    size="small"
                    secondary
                    menuCount={menuCount}
                    onClick={onNavigationClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ORGExternalHeader: React.FC<
  React.PropsWithChildren<IORGExternalHeaderProps>
> = ({ children, ...props }) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGExternalHeader };
