import React, { useMemo } from 'react';
import {
  BarChart,
  PieChart,
  BarProps,
  PieProps,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { LayoutType, Margin } from 'recharts/types/util/types';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMCharts.module.scss';

export type IATMChartProps = BarProps &
  PieProps & {
    data?: any;
    xlabel?: string;
    ylabel?: string;
    chartType?: string;
    layout?: LayoutType;
    title?: string;
    width?: number;
    height?: number;
    margin?: Margin;
    isMobile?: boolean;
    responsiveContainerHeight?: number;
    composedChartHeight?: number;
    scrollXDataKey?: string;
    scrollYDataKey?: string;
    scrollStroke?: string;
    scrollBarDatakey?: string;
    scrollBarSize?: number;
    scrollFillColor?: string;
    maxData?: number;
  };

const ATMChart: React.FC<IATMChartProps> = ({ children, ...props }) => {
  const {
    data,
    xlabel,
    ylabel,
    responsiveContainerHeight = 300,
    composedChartHeight = 250,
    scrollXDataKey = 'name',
    scrollYDataKey = '',
    scrollStroke = '#f5f5f5',
    scrollBarDatakey = 'pv',
    scrollBarSize = 50,
    scrollFillColor = '#B07CB4',
    maxData,
    isMobile = false,
  } = props;
  const [right, setRight] = React.useState(0);
  const MAX_DATA = useMemo(() => {
    if (maxData) return maxData;
    return isMobile ? 8 : 15;
  }, [maxData]); // noOfbar

  const barwithScrollData = useMemo(() => {
    return data.slice(right, MAX_DATA + right);
  }, [data, right]);

  const hideRightButton = useMemo(() => {
    return data.length <= MAX_DATA + right || data.length < MAX_DATA;
  }, [data, right]);

  if (props.chartType === 'composed-vertical') {
    return (
      <div className={styles.main}>
        <div className={styles.yAxisLabel}>
          <p>{ylabel}</p>
        </div>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <BarChart
          margin={props.margin}
          barCategoryGap={17}
          barGap={5}
          layout={props.layout}
          width={props.width}
          height={props.height}
          data={data}
        >
          {children}
        </BarChart>
        {/* </ResponsiveContainer> */}
        <div className={styles.xAxisLabel}>
          <p>{xlabel}</p>
        </div>
      </div>
    );
  }

  if (props.chartType === 'pie-hollow') {
    return (
      <div className={styles.main}>
        <PieChart height={props.height} width={props.width}>
          {children}
        </PieChart>

        <div>
          <p>{xlabel}</p>
        </div>
      </div>
    );
  }

  if (props.chartType === 'bar-with-scroll') {
    return (
      <div className={styles.graphcontainer}>
        {!hideRightButton && (
          <>
            <button
              type="button"
              className={styles.verticalLongButton}
              onClick={() => setRight((prevValue) => prevValue + MAX_DATA)}
            >
              <ATMIcon name="arrow right" />
            </button>
          </>
        )}
        {!(right === 0) && (
          <button
            type="button"
            className={styles.mapPrevious}
            onClick={() => setRight((prevValue) => prevValue - MAX_DATA)}
          >
            <ATMIcon name="arrow left" />
          </button>
        )}
        <ResponsiveContainer height={responsiveContainerHeight}>
          <ComposedChart height={composedChartHeight} data={barwithScrollData}>
            <XAxis dataKey={scrollXDataKey} />
            <YAxis dataKey={scrollYDataKey} />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke={scrollStroke} />
            <Bar
              dataKey={scrollBarDatakey}
              barSize={scrollBarSize}
              fill={scrollFillColor}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return null;
};

export { ATMChart };
