import React from 'react';
import { Progress, ProgressProps } from 'semantic-ui-react';
import classNames from 'classnames';
import './ATMProgressbar.module.scss';

export type IATMProgressbarProps = ProgressProps & {
  size?: string;
};

export const ATMProgressbar: React.FC<ProgressProps> = ({ ...props }) => (
  <Progress {...props} className={classNames(props.className)} />
);
