import React, { useEffect, useState } from 'react';
import {
  SearchProps,
  Search,
  SearchResultProps,
  SearchResultsProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import { ATMDropdown } from '../ATMDropdown/ATMDropdown.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMSearch.module.scss';

export interface IATMSearch extends React.FC<SearchProps> {
  Category: typeof Search.Category;
  Result: typeof Search.Result;
  Results: typeof Search.Results;
  custom?: boolean;
  dropdownPlaceholder?: string;
  dropdownMenu?: React.ReactNode;
  handleSearchChange?: any;
  resultRender?: any;
  categoryLayoutRender?: any;
  noResultsDescription?: React.ReactNode;
  dropDownDefaultValue?: string;
  handleDropdownChange?: any;
  activeInputFlag?: boolean;
  searchClassName?: string;
  handleClear?: any;
}

const ATMSearch: IATMSearch = (props) => {
  const addClass = (el, className) => {
    if (el.classList) el.classList.add(className);
  };
  const removeClass = (el, className) => {
    if (el.classList) el.classList.remove(className);
  };

  const [screentWidth, setScreentWidth] = useState(
    document?.getElementById('parentContainer')?.scrollWidth
  );

  const handleParentResize = () => {
    setScreentWidth(document?.getElementById('parentContainer')?.scrollWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleParentResize);

    return () => {
      window.removeEventListener('resize', handleParentResize);
    };
  }, []);

  useEffect(() => {
    const el = document.getElementById('customSearch');
    if (el) removeClass(el, 'prompt');
  }, []);

  const handleCustomDropDownMouseEnter = () => {
    const dropDownElement = document.getElementById('customDropDown');
    if (dropDownElement) addClass(dropDownElement, styles.activeDropdown);
    const searchElement = document.getElementById('customSearch');
    if (searchElement) addClass(searchElement, styles.activeInput);
  };

  const handleCustomDropDownMouseLeave = () => {
    const dropDownElement = document.getElementById('customDropDown');
    if (dropDownElement) removeClass(dropDownElement, styles.activeDropdown);
    const searchElement = document.getElementById('customSearch');
    if (searchElement) removeClass(searchElement, styles.activeInput);
  };

  const handleCustomSearchMouseEnter = () => {
    const dropDownElement = document.getElementById('customDropDown');
    if (dropDownElement) addClass(dropDownElement, styles.activeDropdown);
    const searchElement = document.getElementById('customSearch');
    if (searchElement) addClass(searchElement, styles.activeInput);
  };

  const handleCustomSearchMouseLeave = () => {
    const dropDownElement = document.getElementById('customDropDown');
    if (dropDownElement) removeClass(dropDownElement, styles.activeDropdown);
    const searchElement = document.getElementById('customSearch');
    if (searchElement) removeClass(searchElement, styles.activeInput);
  };

  if (props.custom) {
    return (
      <div className={styles.customSeachflex}>
        {props.options && (
          <ATMDropdown
            id="customDropDown"
            defaultSelectedLabel=""
            onMouseEnter={handleCustomDropDownMouseEnter}
            onMouseLeave={handleCustomDropDownMouseLeave}
            selection
            floating
            options={props.options}
            className={styles.dropDownBorderFormat}
            defaultValue={
              props.dropDownDefaultValue ? props.dropDownDefaultValue : 'All'
            }
            onChange={(event, val) => props.handleDropdownChange(event, val)}
          />
        )}

        <Search
          id="customSearch"
          icon={
            // eslint-disable-next-line no-nested-ternary
            props.value?.length === 0 ? (
              'search'
            ) : screentWidth && screentWidth <= 722 ? (
              <ATMIcon name="times circle" link onClick={props.handleClear} />
            ) : (
              'search'
            )
          }
          className={styles.searchBorderFormat}
          onMouseEnter={handleCustomSearchMouseEnter}
          onMouseLeave={handleCustomSearchMouseLeave}
          placeholder={props.searchPlaceholder}
          onFocus={props.handleSearchChange}
          onSearchChange={props.handleSearchChange}
          resultRenderer={props.resultRender}
          categoryLayoutRenderer={props.categoryLayoutRender}
          noResultsDescription={props.noResultsDescription}
          {...props}
        />
      </div>
    );
  }

  return (
    <Search
      placeholder="Search"
      onChange={(event, val) => props.handleDropdownChange(event, val)}
      {...props}
      className={classNames(props.className, {
        [styles.mini]: props.size === 'mini',
        [styles.tiny]: props.size === 'tiny',
        [styles.small]: props.size === 'small',
        [styles.large]: props.size === 'large',
        [styles.small]: props.size === undefined,
      })}
    />
  );
};

ATMSearch.Category = Search.Category;
ATMSearch.Result = Search.Result;
ATMSearch.Results = Search.Results;

export type IATMSearchProps = SearchProps;
export type IATMSearchResultProps = SearchResultProps;
export type IATMSearchResultsProps = SearchResultsProps;

export { ATMSearch };
