import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { SelectProps } from 'react-multi-select-component/dist';
import classNames from 'classnames';
import styles from './ATMMultiSelect.module.scss';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';

export type IATMMultiSelectProps = Partial<SelectProps> & {
  size?: string;
  hasSelectAll?: boolean;
  disableSearch?: boolean;
};

export const ATMMultiSelect: React.FC<IATMMultiSelectProps> = ({
  size = 'small',
  hasSelectAll = false,
  disableSearch = true,
  ...props
}) => {
  const valueRenderer = (selected: typeof props.options) => {
    if (!selected?.length) {
      return 'No Item Selected';
    }

    return selected.length === 1
      ? `${selected[0].label}`
      : `${selected?.length} Items Selected`;
  };
  const ArrowRenderer = () => (
    <ATMIcon name="caret down" className={styles.iconCss} />
  );
  const CustomClearIcon = () => (
    <div>
      {' '}
      <ATMIcon name="close" className={styles.closeiconCss} />
    </div>
  );
  const [selectedOption, setSelectedOption] = useState<typeof props.options>(
    []
  );
  return (
    <MultiSelect
      options={props.options as any}
      value={selectedOption as any}
      onChange={setSelectedOption}
      labelledBy="Select"
      hasSelectAll={hasSelectAll} // select all option
      ArrowRenderer={ArrowRenderer}
      disableSearch={disableSearch}
      valueRenderer={valueRenderer}
      ClearSelectedIcon={<CustomClearIcon />}
      className={classNames(
        'multi-select',
        props.className,
        // styles.multiSelectCss,
        {
          [styles.tiny]: size === 'tiny',
          [styles.small]: size === 'small',
          [styles.large]: size === 'large',
        }
      )}
      {...props}
    />
  );
};
