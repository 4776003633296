import React from 'react';
import classNames from 'classnames';
import { Sidebar, SidebarProps } from 'semantic-ui-react';

export type ISidebarProps = SidebarProps;

export const ATMSidebar: React.FC<ISidebarProps> = ({ children, ...props }) => (
  <Sidebar {...props} className={classNames(props.className)}>
    {children}
  </Sidebar>
);
