import React from 'react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLNoData.module.scss';

export type IMOLNoDataProps = {
  icon?: SemanticICONS;
  header?: string;
  subHeader?: string;
  iconNoSize?: boolean;
  iconSize?: IconSizeProp;
};

const MOLNoData: React.FC<IMOLNoDataProps> = ({
  icon,
  header,
  subHeader,
  iconNoSize,
  iconSize = 'big',
}) => {
  return (
    <div className={styles.noData}>
      {iconNoSize ? (
        <ATMIcon name={icon} />
      ) : (
        <ATMIcon name={icon} size={iconSize} />
      )}
      <ATMHeader as="h3" className={styles.sempraTinyMarginBottom}>
        {header}
      </ATMHeader>
      <p style={{ fontSize: '12px' }}>{subHeader}</p>
    </div>
  );
};

export { MOLNoData };
