import React, { useState, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { IMultiselectProps } from 'multiselect-react-dropdown/dist/multiselect/interface';
import classNames from 'classnames';
import styles from './ATMMultiDropdown.module.scss';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import { ATMDropdown } from '../ATMDropdown/ATMDropdown.component';
import { ATMDivider } from '../ATMDivider/ATMDivider.component';

export type IATMMultiDropdownProps = Partial<IMultiselectProps> & {
  size?: string;
  customCloseIcon?: any;
  groupSelect?: boolean;
  getRemainingData?: (any) => void;
  dropdownText?: string;
  multiselectRef?: React.MutableRefObject<Multiselect | null>;
};

// https://www.w3schools.com/howto/howto_custom_select.asp - custom CSS

export const ATMMultiDropdown: React.FC<IATMMultiDropdownProps> = ({
  size = 'small',
  customCloseIcon = <ATMIcon name="close" className={styles.multiIconCss} />,
  groupSelect = false,
  getRemainingData,
  dropdownText = 'Select',
  multiselectRef,
  ...props
}) => {
  const [groupOptionsNew, setGroupOptionsNew] = useState(props.options);
  const [selectedItems, setSelectedItems] = useState([]);
  const onClickHandler = (e) => {
    const gName = e.target.dataset.val;
    setGroupOptionsNew(
      groupOptionsNew.filter((item) => item.groupName !== gName)
    );
  };

  useEffect(() => {
    setGroupOptionsNew(props.options);
  }, [props.options]);

  const subItemClickHandler = (e) => {
    const { dataset } = e.target; // eslint-disable-line
    const selectedGroup = JSON.parse(dataset.groupval);
    const selectedSubVal = dataset.subval;
    setGroupOptionsNew((prev) => {
      return prev.map((item) => {
        if (item.groupName === selectedGroup.groupName) {
          return {
            ...item,
            subItems: item.subItems.filter(
              (subItem) => subItem.text !== selectedSubVal
            ),
          };
        }
        return item;
      });
    });
  };

  const showNewDD = () => {
    return (
      <>
        <ATMDropdown
          selection
          text={dropdownText}
          className={classNames(props.className, styles.customdd, {
            [styles.tiny]: size === 'tiny',
            [styles.small]: size === 'small',
            [styles.large]: size === 'large',
          })}
        >
          <ATMDropdown.Menu>
            {groupOptionsNew.length > 0 ? (
              groupOptionsNew.map((oneGroup, index) => {
                return (
                  <>
                    {oneGroup.subItems.length > 0 && (
                      <>
                        <li key={index}>
                          <div
                            key={index}
                            onClick={onClickHandler}
                            role="button"
                            data-val={oneGroup.groupName}
                            className={styles.groupHeadingCss}
                          >
                            {oneGroup.groupName}
                          </div>
                        </li>
                      </>
                    )}
                    {oneGroup.subItems.map((childItems, i) => {
                      return (
                        <div
                          role="button"
                          key={i}
                          onClick={subItemClickHandler}
                          data-groupval={JSON.stringify(oneGroup)}
                          data-subval={childItems.text}
                          className={styles.subItemCss}
                        >
                          {childItems.text}
                        </div>
                      );
                    })}
                    {index !== groupOptionsNew.length - 1 && (
                      <ATMDivider className={styles.dividerMargin} />
                    )}
                  </>
                );
              })
            ) : (
              <div className={styles.noItemsPadding}>No Items</div>
            )}
          </ATMDropdown.Menu>
        </ATMDropdown>
      </>
    );
  };

  useEffect(() => {
    setSelectedItems(groupOptionsNew);
  }, [groupOptionsNew]);

  useEffect(() => {
    getRemainingData?.(selectedItems);
  }, [selectedItems]);
  if (groupSelect) {
    return showNewDD();
  }
  return (
    <Multiselect
      displayValue="key"
      ref={multiselectRef}
      options={props.options}
      customCloseIcon={customCloseIcon}
      className={classNames(props.className, {
        [styles.tiny]: size === 'tiny',
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
      })}
      {...props}
    />
  );
};
