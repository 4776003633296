import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Visibility, Sidebar, Grid } from 'semantic-ui-react';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import {
  MOLNavigation,
  IMOLNavigationMenuProps,
  INavigationLocation,
} from '../../molecules/MOLNavigation/MOLNavigation.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import styles from './ORGHeader.module.scss';
import {
  MOLUserMenu,
  IATMMOLMenuProps,
} from '../../molecules/MOLUserMenu/MOLUserMenu.component';
import { MOLAppDropdown } from '../../molecules/MOLAppDropdown/MOLAppDropdown.component';
import { ATMLabel } from '../../atoms/ATMLabel/ATMLabel.component';
import {
  ATMSearch,
  IATMSearchProps,
} from '../../atoms/ATMSearch/ATMSearch.component';
import { ATMBrandLogo } from '../../atoms/ATMBrandLogo/ATMBrandLogo.component';
import { IATMMenuItemProps } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';
import { ATMButton } from '../../atoms/ATMButton/ATMButton.component';

export type notification = {
  id: number;
  duration: string;
  content: string;
  status: boolean;
  read: boolean;
  isNew: boolean;
};

export enum notificationEvent {
  MARK_ALL_READ = 'mark_all_read',
  DISMISS_ALL = 'dismiss_all',
}

export type IORGHeaderProps = {
  homeLink?: string;
  location: INavigationLocation;
  handleLocation: (url: string) => void;
  environment?: string | boolean;
  utility?: React.ReactNode;
  application?: boolean | string;
  menus: IMOLNavigationMenuProps[];
  hideMenus?: boolean;
  search?: IATMSearchProps;
  showUser?: boolean;
  user?: IATMMOLMenuProps['user'];
  onNavigationClick?: (data: IATMMenuItemProps) => void;
  onLogout?: () => void;
  siteLink?: boolean;
  sitePath?: string;
  siteName?: string;
  showHelp?: boolean;
  logoName?: string;
  helpLinkUrl?: string;
  additionalMenu?: React.ReactNode;
  menuCount?: number;
  globalSearch?: React.ReactNode;
  isBellIcon?: boolean;
  notifications?: Array<notification>;
  onDismissNotification?: (id: number) => void;
  onMarkOrReadAllNotification?: (
    ids: Array<number>,
    type: notificationEvent
  ) => void;
  closeSearch?: boolean;
  headerActions?: React.ReactNode;
  isTitleMenu?: boolean;
  logoWidth?: number;
  handleNotificationRead?: (id: number) => void;
  onBellIconOpen?: (...args) => void;
  onBellIconClose?: (...args) => void;
  launchButtonContent?: JSX.Element;
  isLaunchIcon?: boolean;
};

const DesktopContainer: React.FC<React.PropsWithChildren<IORGHeaderProps>> = ({
  homeLink = '/',
  environment = false,
  menus,
  hideMenus = false,
  application = false,
  utility,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
  siteLink = false,
  sitePath = '#',
  siteName = 'View App site',
  showHelp = false,
  logoName = 'Sempra Energy',
  helpLinkUrl = '/userguide',
  menuCount,
  additionalMenu,
  globalSearch,
  isBellIcon = false,
  notifications = [
    {
      id: 1,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: true,
      isNew: true,
    },
    {
      id: 2,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: false,
      isNew: false,
    },
    {
      id: 3,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: true,
      isNew: false,
    },
  ],
  onDismissNotification,
  onMarkOrReadAllNotification,
  headerActions,
  isTitleMenu = false,
  logoWidth,
  handleNotificationRead,
  onBellIconClose,
  onBellIconOpen,
  launchButtonContent,
  isLaunchIcon = true,
  location,
  handleLocation,
}) => {
  const [fixed, setFixed] = useState(false);
  const handleNotificationAll = (type: notificationEvent) => {
    const ids: Array<number> = notifications.reduce((prev, current) => {
      return [...prev, current.id];
    }, [] as Array<number>);
    if (onMarkOrReadAllNotification) onMarkOrReadAllNotification(ids, type);
  };
  const getBgColor = (isRead, isNew) => {
    if (isNew) {
      return '#E8F3F8';
    }
    if (isRead) {
      return '#c1c1c18f';
    }
    return 'transparent';
  };

  const notificationCount: number = useMemo(() => {
    return notifications.filter(
      (noti: notification) => noti.isNew || !noti.read
    ).length;
  }, [notifications]);

  return (
    <div id="parentContainer">
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <div className={styles.wrapper}>
          <div
            className={
              !isBellIcon ? styles.sempraNewHeader : styles.sempraNewHeaderBell
            }
          >
            <div>
              <ul className={styles.branding}>
                <li
                  className={
                    logoName.toString().toLowerCase() === 'socal'
                      ? styles.logoSocal
                      : styles.newHeaderlogoOther
                  }
                >
                  <a
                    onClick={
                      handleLocation
                        ? () => handleLocation(homeLink)
                        : undefined
                    }
                    className={styles.logo}
                    role="button"
                  >
                    <ATMBrandLogo
                      // height={30}
                      width={logoWidth}
                      logoName={logoName}
                      color="#ffffff"
                    />
                    <h1>Sempra Energy</h1>
                  </a>
                </li>
                {utility && <li>{utility}</li>}
                {application && (
                  <li>
                    <div className={styles.newHeaderApplicationContainer}>
                      <MOLAppDropdown application={application} />
                    </div>
                  </li>
                )}
                {environment !== false &&
                  (!environment.toString().toLowerCase().includes('prod') ||
                    !environment.toString().toLowerCase().includes('prd')) && (
                    <li
                      className={
                        logoName.toString().toLowerCase() === 'socal'
                          ? styles.logoSocal
                          : styles.newHeaderlogoOther
                      }
                    >
                      {logoName === 'socal gas' ? (
                        <ATMLabel
                          className={
                            environment.toString().toLowerCase().includes('dev')
                              ? styles.environmentDev
                              : styles.environmentSocal
                          }
                          horizontal
                        >
                          {environment}
                        </ATMLabel>
                      ) : (
                        <ATMLabel
                          className={
                            environment.toString().toLowerCase().includes('dev')
                              ? styles.environmentDev
                              : styles.environmentTestProd
                          }
                          horizontal
                        >
                          {`${environment || 'Dev'}`}
                        </ATMLabel>
                      )}
                    </li>
                  )}
              </ul>
            </div>
            {search && (
              <div>
                <ATMSearch
                  size="mini"
                  {...search}
                  className={classNames(styles.searchBar, search.className)}
                />
              </div>
            )}
            {globalSearch && (
              <div className={styles.globalSearchBar}> {globalSearch}</div>
            )}
            <div
              className={styles.displayFlex}
              style={{ position: 'relative' }}
            >
              {showHelp && (
                <div className={styles.helpLink}>
                  <ATMIcon
                    className={styles.helpIcon}
                    onClick={() => {
                      // eslint-disable-next-line security/detect-non-literal-fs-filename
                      window.open(`${helpLinkUrl}`, '_blank');
                    }}
                    circular
                    name="help"
                  />
                </div>
              )}

              {isBellIcon && (
                <div>
                  <ATMPopover
                    on="click"
                    onClose={(...args) =>
                      onBellIconClose && onBellIconClose(...args)
                    }
                    onOpen={(...args) =>
                      onBellIconOpen && onBellIconOpen(...args)
                    }
                    trigger={
                      <div className={styles.bellPointer}>
                        {!!notificationCount && (
                          <span className={styles.notificationBadge}>
                            {notificationCount}
                          </span>
                        )}
                        <ATMIcon name="bell outline" size="large" />
                      </div>
                    }
                    style={{ minWidth: '312px' }}
                    content={
                      <div>
                        <Grid className={styles.notificationHead}>
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <strong>
                                Notifications ({notifications.length})
                              </strong>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <ATMButton
                                noborder
                                onClick={() =>
                                  handleNotificationAll(
                                    notificationEvent.MARK_ALL_READ
                                  )
                                }
                                className={styles.notificationBtn}
                              >
                                Mark all as read
                              </ATMButton>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <ATMButton
                                noborder
                                onClick={() =>
                                  handleNotificationAll(
                                    notificationEvent.DISMISS_ALL
                                  )
                                }
                                className={styles.notificationBtn}
                              >
                                Dismiss all
                              </ATMButton>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <div className={styles.notificationCont}>
                          {notifications.map((notification) => (
                            <Grid
                              className={styles.notificationItem}
                              style={{
                                background: getBgColor(
                                  notification.read,
                                  notification.isNew
                                ),
                                cursor: !notification.read
                                  ? 'pointer'
                                  : 'default',
                              }}
                              key={notification.id}
                              onClick={() =>
                                handleNotificationRead
                                  ? handleNotificationRead(notification.id)
                                  : null
                              }
                            >
                              <Grid.Row>
                                <Grid.Column width={3}>
                                  {notification.status ? (
                                    <ATMIcon
                                      name="check circle outline"
                                      size="big"
                                      style={{ color: '#619602' }}
                                    />
                                  ) : (
                                    <ATMIcon
                                      name="exclamation circle"
                                      size="big"
                                      style={{ color: '#E70031' }}
                                    />
                                  )}
                                  <div className="duration">
                                    {notification.duration}
                                  </div>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                  <span className="nt-card__text--3-line">
                                    <span className="white-space-pre"> </span>
                                    <span className="white-space-pre"> </span>
                                    {notification.content}
                                  </span>
                                </Grid.Column>
                                <Grid.Column
                                  width={2}
                                  className={styles.removeBtn}
                                >
                                  <ATMButton
                                    icon="remove"
                                    size="large"
                                    onClick={() =>
                                      onDismissNotification
                                        ? onDismissNotification(notification.id)
                                        : null
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          ))}
                        </div>
                      </div>
                    }
                    size="mini"
                  />
                </div>
              )}

              {headerActions && (
                <div className={styles.notificationIcon}>{headerActions}</div>
              )}

              {showUser ? (
                <div style={{ borderLeft: '1px solid #000000' }}>
                  <MOLUserMenu
                    user={user}
                    onLogout={onLogout}
                    helpLinkUrl={helpLinkUrl}
                    isTitleMenu={isTitleMenu}
                  />
                </div>
              ) : (
                <div style={{ borderLeft: '1px solid #000000' }}>
                  <MOLUserMenu
                    user={user}
                    onLogout={onLogout}
                    helpLinkUrl={helpLinkUrl}
                    socalGas
                    isLaunchIcon
                  />
                  {isLaunchIcon && (
                    <ATMPopover
                      on="click"
                      trigger={
                        <ATMIcon
                          name="th"
                          style={{ marginLeft: '10px', fontSize: '1.2em' }}
                        />
                      }
                      position="top right"
                      style={{ marginTop: '12px', marginRight: '-10px' }}
                      content={launchButtonContent}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            id="navigation"
            // className={
            //   styles.navigation + (fixed ? ` ${styles.fixedNavigation}` : '')
            // }
            className={classNames({
              [styles.navigation]: !hideMenus,
              [styles.fixedNavigation]: fixed,
              [styles.divSpace]: hideMenus,
            })}
          >
            {hideMenus === false ? (
              <div>
                {application !== 'socalMenu' ? (
                  <MOLNavigation
                    location={location}
                    handleLocation={handleLocation}
                    han
                    menus={menus}
                    pointing
                    secondary
                    menuCount={menuCount}
                    onClick={onNavigationClick}
                    size="small"
                    siteLink={siteLink}
                  />
                ) : (
                  <MOLNavigation
                    location={location}
                    handleLocation={handleLocation}
                    menus={menus}
                    pointing
                    secondary
                    menuCount={menuCount}
                    onClick={onNavigationClick}
                    siteLink={siteLink}
                  />
                )}
              </div>
            ) : null}

            {siteLink && (
              <a
                className={styles.siteTag}
                href={sitePath}
                target="_blank"
                rel="noopener noreferrer"
              >
                {siteName}
                <span>
                  <ATMIcon
                    className={styles.siteTag}
                    name="external alternate"
                  />
                </span>
              </a>
            )}
            {additionalMenu && <div> {additionalMenu} </div>}
          </div>
        </div>
      </Visibility>
      {children}
    </div>
  );
};

const MobileContainer: React.FC<React.PropsWithChildren<IORGHeaderProps>> = ({
  homeLink = '/',
  menus,
  hideMenus = false,
  application = false,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
  logoName,
  globalSearch,
  closeSearch,
  logoWidth,
  isBellIcon = false,
  notifications = [
    {
      id: 1,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: true,
      isNew: true,
    },
    {
      id: 2,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: false,
      isNew: false,
    },
    {
      id: 3,
      duration: '45 Mins',
      content: `The
    Regular Bill, dated 05/08/2020 08:07:44, for
      Chevron Energy Solutions Company
    , download was successful.`,
      status: true,
      read: true,
      isNew: false,
    },
  ],
  onDismissNotification,
  onMarkOrReadAllNotification,
  handleNotificationRead,
  onBellIconClose,
  onBellIconOpen,
  location,
  handleLocation,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);
  const [closeSearchBar, setCloseSearchBar] = useState(closeSearch);

  const handleIcon = useCallback(() => {
    setSidebarOpened(true);
  }, [setSidebarOpened]);

  const handleSearchOpened = useCallback(
    (isSearchOpened) => {
      setSearchOpened(isSearchOpened);
      setCloseSearchBar(false);
    },
    [searchOpened, setSearchOpened]
  );

  useEffect(() => {
    if (closeSearch) {
      setCloseSearchBar(closeSearch);
    } else {
      setCloseSearchBar(!closeSearch);
    }
  }, [closeSearch]);

  const handleNotificationAll = (type: notificationEvent) => {
    const ids: Array<number> = notifications.reduce((prev, current) => {
      return [...prev, current.id];
    }, [] as Array<number>);
    if (onMarkOrReadAllNotification) onMarkOrReadAllNotification(ids, type);
  };
  const getBgColor = (isRead, isNew) => {
    if (isNew) {
      return '#E8F3F8';
    }
    if (isRead) {
      return '#c1c1c18f';
    }
    return 'transparent';
  };

  const notificationCount: number = useMemo(() => {
    return notifications.filter(
      (noti: notification) => noti.isNew || !noti.read
    ).length;
  }, [notifications]);

  return (
    <Sidebar.Pushable id="parentContainer">
      <Sidebar
        animation="push"
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
        className={styles.newSidebar}
      >
        {application && (
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
            }}
          >
            <MOLAppDropdown
              application={logoName !== 'socal gas' ? application : ''}
              isMobile
            />
          </div>
        )}
        {hideMenus === false ? (
          <MOLNavigation
            location={location}
            handleLocation={handleLocation}
            menus={menus}
            vertical
            onClick={onNavigationClick}
          />
        ) : null}
        {showUser ? (
          <MOLUserMenu user={user} isMobile onLogout={onLogout} />
        ) : (
          <MOLUserMenu user={user} onLogout={onLogout} socalGas isMobile />
        )}
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened} className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topNewHeaderMobile}>
            <div
              className={classNames(styles.sidebarTrigger, {
                [styles.displayNone]: !(!searchOpened || closeSearchBar),
              })}
            >
              {(!searchOpened || closeSearchBar) && (
                <ATMIcon
                  name={sidebarOpened ? 'arrow left' : 'sidebar'}
                  onClick={handleIcon}
                />
              )}
            </div>
            {(!searchOpened || closeSearchBar) && (
              <div className={styles.notificationMobile}>
                <a
                  onClick={() => handleLocation(homeLink)}
                  className={styles.logo}
                  role="button"
                >
                  <ATMBrandLogo
                    // height={25}
                    width={logoWidth}
                    color="#ffffff"
                    logoName={logoName}
                  />
                  <h1>Sempra Energy</h1>
                </a>
                {isBellIcon && (
                  <div className={styles.paddingWellIcon}>
                    {!!notificationCount && (
                      <span className={styles.notificationBadge}>
                        {notificationCount}
                      </span>
                    )}
                    <ATMPopover
                      onClose={(...args) =>
                        onBellIconClose && onBellIconClose(...args)
                      }
                      onOpen={(...args) =>
                        onBellIconOpen && onBellIconOpen(...args)
                      }
                      trigger={<ATMIcon name="bell outline" size="large" />}
                      style={{ maxWidth: '280px' }}
                      content={
                        <>
                          <Grid className={styles.notificationHead}>
                            <Grid.Row>
                              <Grid.Column width={7}>
                                <strong>
                                  Notifications ({notifications.length})
                                </strong>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <ATMButton
                                  noborder
                                  onClick={() =>
                                    handleNotificationAll(
                                      notificationEvent.MARK_ALL_READ
                                    )
                                  }
                                  className={styles.notificationBtn}
                                >
                                  Mark all as read
                                </ATMButton>
                              </Grid.Column>
                              <Grid.Column width={4}>
                                <ATMButton
                                  noborder
                                  onClick={() =>
                                    handleNotificationAll(
                                      notificationEvent.DISMISS_ALL
                                    )
                                  }
                                  className={styles.notificationBtn}
                                >
                                  Dismiss all
                                </ATMButton>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                          <div className={styles.notificationCont}>
                            {notifications.map((notification) => (
                              <Grid
                                className={styles.notificationItem}
                                style={{
                                  background: getBgColor(
                                    notification.read,
                                    notification.isNew
                                  ),
                                  cursor: !notification.read
                                    ? 'pointer'
                                    : 'default',
                                }}
                                key={notification.id}
                                onClick={() =>
                                  handleNotificationRead
                                    ? handleNotificationRead(notification.id)
                                    : null
                                }
                              >
                                <Grid.Row>
                                  <Grid.Column width={3}>
                                    {notification.status ? (
                                      <ATMIcon
                                        name="check circle outline"
                                        size="big"
                                        style={{ color: '#619602' }}
                                      />
                                    ) : (
                                      <ATMIcon
                                        name="exclamation circle"
                                        size="big"
                                        style={{ color: '#E70031' }}
                                      />
                                    )}
                                    <div className="duration">
                                      {notification.duration}
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column width={11}>
                                    <span className="nt-card__text--3-line">
                                      <span className="white-space-pre"> </span>
                                      <span className="white-space-pre"> </span>
                                      {notification.content}
                                    </span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={2}
                                    className={styles.removeBtn}
                                  >
                                    <ATMButton
                                      icon="remove"
                                      size="large"
                                      onClick={() =>
                                        onDismissNotification
                                          ? onDismissNotification(
                                              notification.id
                                            )
                                          : null
                                      }
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            ))}
                          </div>
                        </>
                      }
                      size="mini"
                      on="click"
                      position="bottom right"
                    />
                  </div>
                )}
              </div>
            )}

            {search && (
              <div
                className={classNames({
                  [styles.search]: true,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <ATMSearch
                    size="mini"
                    {...search}
                    className={classNames(styles.searchBar, search.className)}
                  />
                ) : (
                  <ATMIcon
                    name="search"
                    onClick={() => handleSearchOpened(true)}
                  />
                )}
              </div>
            )}

            {globalSearch && (
              <div
                className={classNames({
                  [styles.search]: true,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <div className={styles.globalSearchBar}>{globalSearch}</div>
                ) : (
                  <ATMIcon
                    name="search"
                    onClick={() => handleSearchOpened(true)}
                  />
                )}
              </div>
            )}

            {(search || globalSearch) && (
              <div
                className={classNames({
                  [styles.close]: searchOpened && !closeSearchBar,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <ATMIcon
                    name="close"
                    onClick={() => handleSearchOpened(false)}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>

        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const ORGHeader: React.FC<React.PropsWithChildren<IORGHeaderProps>> = ({
  children,
  ...props
}) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>

      <ATMResponsive media="mobile">
        <MobileContainer {...props}>{children}</MobileContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGHeader };
