import React from 'react';
import { SidebarProps } from 'semantic-ui-react';
import { ATMSidebar } from '../../atoms/ATMSidebar/ATMSidebar.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLSidebar.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';

export type IMOLSidebarProps = SidebarProps & {
  panelContent?: React.ReactNode;
  content?: React.ReactNode;
  header?: React.ReactNode;
  popoverHeader?: string;
  popoverContent?: string;
  subHeaderContent?: React.ReactNode;
  panelFooter?: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  isOpen?: boolean;
  socalGas?: boolean;
  helpButtonContent?: JSX.Element;
  leftScreenNotBlur?: boolean;
  crosscloseicon?: boolean;
};

const MOLSidebar: React.FC<React.PropsWithChildren<IMOLSidebarProps>> = ({
  panelContent,
  content,
  crosscloseicon = true,
  header,
  popoverHeader,
  popoverContent,
  subHeaderContent,
  panelFooter,
  footer,
  width = '40%',
  isOpen = false,
  socalGas = false,
  helpButtonContent,
  children,
  ...props
}) => {
  return (
    <ATMSidebar
      direction="right"
      className={styles.molSidebarbackground}
      style={{ width }}
      {...props}
      visible={props.open || isOpen}
    >
      {' '}
      {header && (
        <>
          {typeof header !== 'string' ? (
            header
          ) : (
            <>
              <div>
                <div className={styles.panelHeader}>
                  <ATMHeader as="h1" className={styles.textMargin}>
                    {header}
                  </ATMHeader>
                  <div className={styles.flex}>
                    {popoverContent && (
                      <ATMPopover
                        on="click"
                        position="bottom left"
                        className={styles.popoverHeight}
                        basic
                        content={popoverContent}
                        header={popoverHeader}
                        trigger={
                          <ATMIcon
                            name="question circle outline"
                            size="small"
                          />
                        }
                      />
                    )}
                    {socalGas && helpButtonContent}
                    {crosscloseicon && (
                      <div>
                        <ATMIcon
                          name="delete"
                          onClick={props.onClose}
                          size="small"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {subHeaderContent && (
        <div className={styles.panelSubHeader}>{subHeaderContent}</div>
      )}
      <div className={styles.contentArea}>
        {content || panelContent || children}
      </div>
      {(footer || panelFooter) && <>{footer || panelFooter}</>}
    </ATMSidebar>
  );
};

export { MOLSidebar };
