import React, { useState } from 'react';
import { Visibility, Grid, GridColumn, Segment } from 'semantic-ui-react';
import { ATMButton } from '../../atoms/ATMButton/ATMButton.component';
import styles from './ORGHelpPage.module.scss';
import { ATMMenu } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

const DesktopContainer: React.FC<any> = ({ children, adminMenuOptions }) => {
  const [activeItem, setactiveState] = useState<string>('role-maintenance');

  const handleClick = (tabName: string) => {
    setactiveState(tabName);
    const clickValueId: any | null = document.getElementById(tabName);
    const offset = 65;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = clickValueId.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Visibility once={false}>
        <div className={styles.wrapper}>
          <div className={styles.topHeader}>
            <div>
              <ul className={styles.branding}>
                <li className={styles.logoOther}>Major Markets System</li>
                <li className={styles.logoOther}>Help</li>
              </ul>
            </div>
          </div>
        </div>
      </Visibility>
      <div className={styles.adminContainer}>
        <div>
          <ATMMenu
            vertical
            collapsible
            billViewer
            className={styles.sidemenubar}
          >
            <ATMMenu.Item
              content="Bill Viewer"
              className={styles.menuheading}
            />
            {adminMenuOptions.map((menu_option) => {
              return (
                <ATMMenu.Item
                  key={menu_option.key}
                  name={menu_option.key}
                  content={menu_option.name}
                  active={activeItem === menu_option.key}
                  onClick={() => handleClick(menu_option.key)}
                />
              );
            })}
          </ATMMenu>
        </div>
        <div>
          <Grid>
            <GridColumn computer={8} tab={10} mobile={16}>
              Need help with any topic? Find here
            </GridColumn>
          </Grid>
          <Segment>
            {adminMenuOptions.map((adminMenuOption) => (
              <Grid
                className={styles.billintroduction}
                id={`${adminMenuOption.key}`}
                key={`${adminMenuOption.key}`}
              >
                <GridColumn computer={16} mobile={16}>
                  {adminMenuOption.content}
                </GridColumn>
              </Grid>
            ))}
          </Segment>
        </div>
      </div>
      {children}
    </div>
  );
};

const MobileContainer: React.FC<any> = ({ children, adminMenuOptions }) => {
  const [mobileActiveState, setMobileActiveState] =
    useState('role-maintenance');
  const [isClicked, setTabClick] = useState(false);

  const handleClickMobile = (tabName: string) => {
    setMobileActiveState(tabName);
    setTabClick(true);
  };

  return (
    <div>
      <Visibility once={false}>
        <div className={styles.wrapper}>
          <div className={styles.topHeader}>
            <div>
              <ul className={styles.branding}>
                <li className={styles.logoOther}>Major Markets System</li>
                <li className={styles.logoOther}>Help</li>
              </ul>
            </div>
          </div>
        </div>
      </Visibility>
      <Grid className={styles.adminContainer}>
        {!isClicked && (
          <GridColumn
            className="gridColumn paddingLeft"
            computer={3}
            tab={6}
            mobile={16}
          >
            <Grid>
              <GridColumn
                computer={8}
                tab={10}
                mobile={16}
                style={{ paddingTop: '0px', textAlign: 'center' }}
              >
                Need help with any topic? Find here
              </GridColumn>
            </Grid>
            <ATMMenu fluid vertical>
              <ATMMenu.Item
                content="Bill Viewer"
                className={styles.menuheading}
              />
              {adminMenuOptions.map((menu_option) => {
                return (
                  <ATMMenu.Item
                    key={menu_option.key}
                    name={menu_option.key}
                    active={mobileActiveState === menu_option.key}
                    onClick={() => handleClickMobile(menu_option.key)}
                    content={menu_option.name}
                  />
                );
              })}
            </ATMMenu>
          </GridColumn>
        )}
        {isClicked && (
          <GridColumn
            className="gridColumn1 paddingLeft"
            computer={13}
            tab={10}
            mobile={16}
          >
            {adminMenuOptions.map(
              (adminMenuOption) =>
                mobileActiveState === adminMenuOption.key && (
                  <Segment>
                    <ATMButton
                      className="noBorderBtn"
                      secondary
                      content="Back"
                      icon="left arrow"
                      onClick={() => setTabClick(false)}
                      style={{ boxShadow: 'none', marginTop: '10px' }}
                    />
                    <Grid className={styles.billintroduction}>
                      <GridColumn computer={16} tab={16} mobile={16}>
                        {adminMenuOption.content}
                      </GridColumn>
                    </Grid>
                  </Segment>
                )
            )}
          </GridColumn>
        )}
      </Grid>
      {children}
    </div>
  );
};

const ORGHelpPage: React.FC<any> = ({ children, ...props }) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>
      <ATMResponsive media="mobile">
        <MobileContainer {...props}>{children}</MobileContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGHelpPage };
