import React from 'react';
import { DropdownItemProps, Select, SelectProps } from 'semantic-ui-react';
import classNames from 'classnames';
import opt from '../../../libraries/states.library';
import styles from './ATMSelect.module.scss';
import { customSize } from '../ATMDropdown/ATMDropdown.component';

export type IATMSelectProps = SelectProps;

type INewProps = Omit<SelectProps, 'options'>;
interface ISelectProps extends INewProps {
  states?: boolean;
  options?: DropdownItemProps[];
  size?: customSize;
}

export const ATMSelect: React.FC<React.PropsWithChildren<ISelectProps>> = ({
  children,
  size = 'small',
  states = false,
  ...props
}) => {
  if (states) {
    const { options, ...otherProps } = props;
    const stateOption = Object.entries(opt.STATES).map(([key, state]) => ({
      value: key,
      text: state,
    }));
    return (
      <Select
        className={classNames(props.className, {
          [styles.tiny]: size === 'tiny',
          [styles.small]: size === 'small',
          [styles.large]: size === 'large',
        })}
        options={stateOption}
        {...otherProps}
      >
        {children}
      </Select>
    );
  }
  return (
    <Select
      className={classNames(props.className, {
        [styles.tiny]: size === 'tiny',
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
      })}
      options={[]}
      {...props}
    >
      {children}
    </Select>
  );
};
