import React from 'react';
import { Container, ContainerProps } from 'semantic-ui-react';

export type IContainerProps = ContainerProps;

const ATMContainer: React.FC<React.PropsWithChildren<IContainerProps>> = ({
  children,
  ...props
}) => <Container {...props}>{children}</Container>;

export { ATMContainer };
