import React, { useMemo, useState } from 'react';
import { ATMDropdown } from '../../atoms/ATMDropdown/ATMDropdown.component';
import { ATMInput } from '../../atoms/ATMInput/ATMInput.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMMenu } from '../../atoms/ATMMenu/ATMMenu.component';
import styles from './MOLAppDropdown.module.scss';

const appOptions = [
  {
    key: 'rcs',
    text: 'Regulatory Compliance System',
    value: 'rcs',
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'vr',
    text: 'Vehicle Rental',
    value: 'vr',
    label: { color: 'green', empty: true, circular: true },
  },
];

const filterText = (keyword: string, text: string) => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(
    keyword.toLocaleString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    'i'
  );

  return text && regex.test(text.toString().toLocaleString());
};

type IProps = {
  application: boolean | string;
  isMobile?: boolean;
};

export const MOLAppDropdown: React.FC<IProps> = ({
  isMobile = false,
  application,
}) => {
  const [selected, setSelected] = useState(appOptions[0].text);
  const [keyword, setKeyword] = useState('');

  const trigger = useMemo(
    () => (
      <span>
        <ATMIcon name="th" /> {selected}
      </span>
    ),
    [selected]
  );

  if (typeof application === 'string') {
    return (
      <h2 className={styles.title} title={application}>
        {application}
      </h2>
    );
  }

  const content = (
    <ATMDropdown.Menu>
      <ATMInput
        icon="search"
        placeholder="Search Apps"
        value={keyword}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <ATMDropdown.Menu scrolling>
        {appOptions
          .filter((value) => filterText(keyword, value.text))
          .map(({ key, ...option }) => (
            <ATMDropdown.Item
              key={key}
              {...option}
              onClick={() => setSelected(option.text)}
            />
          ))}
      </ATMDropdown.Menu>
    </ATMDropdown.Menu>
  );

  if (isMobile) {
    return (
      <ATMMenu vertical>
        <ATMDropdown item text="Categories">
          {content}
        </ATMDropdown>
      </ATMMenu>
    );
  }

  return (
    <ATMDropdown
      trigger={trigger}
      pointing="top left"
      onClose={() => setKeyword('')}
      className={styles.navigation}
    >
      {content}
    </ATMDropdown>
  );
};
