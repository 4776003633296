import React, { useMemo, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { ATMImage } from '../../atoms/ATMImage/ATMImage.component';
import {
  ATMDropdown,
  IATMDropdownItemProps,
} from '../../atoms/ATMDropdown/ATMDropdown.component';
import { ATMMenu } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import styles from './MOLUserMenu.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';

type IATMUser = {
  first_name?: string;
  last_name?: string;
  name?: string;
  username?: string;
  avatar?: string;
  fullName?: string;
  userDetails?: { key: string; value: string }[];
};

export type IATMMOLMenuProps = {
  user?: IATMUser;
  isMobile?: boolean;
  onLogout?: () => void;
  helpLinkUrl?: string;
  socalGas?: boolean;
  isTitleMenu?: boolean;
  isLaunchIcon?: boolean;
};

export interface IATMDropDownOptions extends IATMDropdownItemProps {
  onClick?: () => void;
}

const MOLUserMenu: React.FC<IATMMOLMenuProps> = ({
  user,
  isMobile = false,
  onLogout,
  helpLinkUrl = '/userguide',
  socalGas = false,
  isTitleMenu = false,
}) => {
  const [popOpen, setPopOpen] = useState<boolean>(false);
  const [popOpenIsMobile, setPopOpenIsMobile] = useState<boolean>(false);

  // const userAvtarImage = useMemo(() => {
  //   if (isLaunchIcon) {
  //     return <ATMIcon name="user circle" size="large" />;
  //   }
  //   return <ATMImage src={user?.avatar} avatar />;
  // }, [isLaunchIcon, user]);

  const trigger = useMemo(() => {
    let content = 'Welcome';

    if (user) {
      if (user.username) {
        content = user.username;
      } else if (user.name) {
        content = user.name;
      } else if (user.first_name && user.last_name) {
        content = `${user.first_name} ${user.last_name}`;
      } else if (user.fullName) {
        content = user.fullName;
      }
    }
    return (
      <div className={styles.header}>
        <span
          className={
            user && Object.keys(user).length ? styles.fadeIn : styles.mr0
          }
        >
          {content}
        </span>
        {user?.avatar ? (
          <ATMImage src={user?.avatar} avatar />
        ) : (
          <div className={styles.avatar}>
            <ATMIcon name="user" size="small" />
          </div>
        )}
      </div>
    );
  }, [user]);

  const options: Array<IATMDropDownOptions> = socalGas
    ? [
        {
          key: 'account',
          text: 'My Account Info',
          icon: 'user circle outline',
          onClick: () => {
            if (isMobile) {
              setPopOpenIsMobile((prev) => !prev);
            } else {
              setPopOpen(true);
            }
          },
        },
        {
          key: 'sign-out',
          text: 'Logout',
          icon: 'sign out',
          onClick: () => {
            if (onLogout) {
              onLogout();
            }
          },
        },
      ]
    : [
        {
          key: 'account',
          text: 'Account Settings',
          icon: 'cog',
          link: 'https://myaccount.microsoft.com/',
          onClick: () => {
            window.open('https://myaccount.microsoft.com/', '_blank');
          },
        },
        {
          key: 'help',
          text: 'Help',
          icon: 'help',
          link: helpLinkUrl,
          onClick: () => {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(`${helpLinkUrl}`, '_blank');
          },
        },

        {
          key: 'sign-out',
          text: 'Logout',
          icon: 'sign out',
          link: '',
          onClick: () => {
            if (onLogout) {
              onLogout();
            }
          },
        },
      ];

  const tilteTrigger = useMemo(() => {
    let initials = '';
    if (user?.fullName) {
      const fullName = user?.fullName?.split(' ');
      if (fullName?.length === 1) {
        initials = fullName.shift()?.charAt(0).toUpperCase() || '';
      } else {
        const firstChar = fullName.shift()?.charAt(0).toUpperCase();
        const lastChar = fullName.pop()?.charAt(0).toUpperCase();
        let fullChar = '';
        if (firstChar && lastChar) {
          fullChar = firstChar + lastChar;
        }
        initials = fullChar;
      }
    }

    return (
      <div className={styles.header}>
        <span
          style={{
            backgroundColor: '#010f3d',
            border: '1px solid #fff',
            fontWeight: 600,
          }}
          className={styles.initials}
        >
          {initials}
        </span>
      </div>
    );
  }, [user]);

  if (isMobile) {
    return (
      <ATMMenu
        vertical
        className={!popOpenIsMobile ? styles.mobileView : styles.mobileViewOpen}
      >
        <ATMMenu.Item key="trigger">{trigger}</ATMMenu.Item>
        <ATMMenu.Item onClick={() => setPopOpenIsMobile((prev) => !prev)}>
          <ATMIcon name="user circle outline" size="large" />
          My Account Info
        </ATMMenu.Item>
        {popOpenIsMobile && (
          <div className={styles.moreaccountinfo}>
            <Grid
              verticalAlign="middle"
              onClick={() => setPopOpenIsMobile(false)}
            >
              {/* <Grid.Column width={5} textAlign="right">
                <ATMIcon name="angle left" size="large" />
                <ATMIcon name="user circle outline" />
              </Grid.Column>
              <Grid.Column textAlign="left" width={11}>
                My Account Info
              </Grid.Column> */}
            </Grid>
            <Grid
              verticalAlign="top"
              className={styles.pt1}
              onClick={() => setPopOpen(false)}
            >
              {user?.userDetails &&
                user?.userDetails.map((userData, index) => (
                  <>
                    <Grid.Column
                      textAlign="right"
                      width={4}
                      className={styles.py0}
                      key={index}
                    >
                      {userData.key}:
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={12}
                      className={styles.py0}
                      style={{ wordBreak: 'break-all' }}
                    >
                      {userData.value}
                    </Grid.Column>
                  </>
                ))}
            </Grid>
          </div>
        )}
        <ATMMenu.Item onClick={() => onLogout && onLogout()}>
          <ATMIcon name="sign out" size="large" />
          Logout
        </ATMMenu.Item>
      </ATMMenu>
    );
  }

  const menuContent = () => {
    return (
      <>
        <div
          style={{ borderBottom: '1px solid #cfcfcd', paddingBottom: '0.5rem' }}
        >
          <strong>{user && user.fullName}</strong>
        </div>
        <div className={styles.menuOptions}>
          {options.map((optionObj, index) => (
            <li key={index}>
              <div
                role="link"
                className={styles.menuList}
                // eslint-disable-next-line security/detect-non-literal-fs-filename
                onClick={() => window.open(optionObj.link, '_blank')}
              >
                <ATMIcon name={optionObj.icon as any} />
                <p>{optionObj.text}</p>
              </div>
            </li>
          ))}
        </div>
      </>
    );
  };
  if (isTitleMenu) {
    return (
      <>
        <div className={styles.header} style={{ display: 'flex' }}>
          <ATMPopover
            trigger={tilteTrigger}
            on="click"
            content={menuContent}
            pointing="top right"
            className={styles.menuDataContent}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {!popOpen ? (
        <ATMDropdown
          trigger={trigger}
          options={options}
          pointing="top right"
          icon={null}
        />
      ) : (
        <>
          <ATMDropdown
            trigger={trigger}
            options={options}
            pointing="top right"
            icon={null}
          />
          <div className={styles.moreaccountinfo}>
            <Grid verticalAlign="middle" onClick={() => setPopOpen(false)}>
              <Grid.Column width={4}>
                <ATMIcon name="angle left" size="large" color="blue" />
                <ATMIcon name="user circle outline" />
              </Grid.Column>
              <Grid.Column textAlign="left" width={12}>
                My Account Info
              </Grid.Column>
            </Grid>
            <Grid
              verticalAlign="middle"
              className={styles.pt1}
              onClick={() => setPopOpen(false)}
            >
              {user?.userDetails &&
                user?.userDetails.map((userData, index) => (
                  <>
                    <Grid.Column
                      textAlign="right"
                      width={5}
                      className={styles.py0}
                      key={index}
                    >
                      {userData.key}:
                    </Grid.Column>
                    <Grid.Column
                      textAlign="left"
                      width={11}
                      className={styles.py0}
                    >
                      {userData.value}
                    </Grid.Column>
                  </>
                ))}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export { MOLUserMenu };
